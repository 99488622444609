import React from 'react'
import { Link } from 'gatsby'

import Title from '../../components/sharedComponents/Title/Title'
import FormBlock from '../../components/mainComponents/FormBlock/FormBlock'
import Seo from '../../components/Seo'

import '../../assets/css/stylesheet.css'
import * as styles from './styles.module.scss'
import '../../components/image.scss'

const Index = ({ pageContext: { data } }) => (
  <>
    <Seo
      title={'Justice | Industries'}
      description={data.text1.substring(0, 200)}
      htmlAttributes={{
        lang: 'en',
      }}
      url="https://www.it-justice.com/"
    />
    <div className={styles.industries}>
      <div className="container">
        <div className={styles.industriesItem}>
          <div className={styles.industriesItemTop}>
            <div className={styles.industriesItemTopLeft}>
              <Title>{data.title}</Title>
              <div className={styles.text}>{data.text1}</div>
              <Link to={data.link} className={`${styles.button}`}>
                {data.linkText}
              </Link>
            </div>
            <div className={styles.industriesItemTopRight}>
              <div className={data.img1} />
              <div
                style={{ backgroundColor: `${data.color}` }}
                className={styles.industriesItemTopRightAfter}
              />
            </div>
          </div>
          <div className={styles.industriesItemBottom}>
            <div
              style={{ backgroundColor: `${data.color}` }}
              className={styles.industriesItemBottomLeft}
            >
              <div className={data.img2} />
            </div>
            <div className={styles.industriesItemBottomRight}>
              <div className={styles.text}>{data.text2}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormBlock />
  </>
)

export default Index
