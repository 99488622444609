// extracted by mini-css-extract-plugin
export var industries = "styles-module--industries--aYb5a";
export var industriesItem = "styles-module--industries-item--MF+Pt";
export var industriesItemTop = "styles-module--industries-item-top--7dKpC";
export var industriesItemTopLeft = "styles-module--industries-item-top-left--kUrDA";
export var industriesItemTopRight = "styles-module--industries-item-top-right--AklC2";
export var industriesItemTopRightAfter = "styles-module--industries-item-top-right-after--gAtXV";
export var industriesItemBottom = "styles-module--industries-item-bottom--lPMlI";
export var industriesItemBottomLeft = "styles-module--industries-item-bottom-left--wTxmO";
export var industriesItemBottomRight = "styles-module--industries-item-bottom-right--9+cHa";
export var text = "styles-module--text--Ytq5g";
export var button = "styles-module--button--woG8i";